import React from 'react'
import type { PageProps } from 'gatsby'
import { Flex } from '@chakra-ui/react'
import Navbar from 'components/Navbar'
import Body from 'components/Body'
import Footer from 'components/Footer'

function Layout({ children, ...rest }: React.PropsWithChildren<PageProps>) {
  console.log(`[Layout] Props`, rest)

  return (
    <Flex id='ftm-container' direction='column'>
      <Navbar id='ftm-navbar' />
      <Body id='ftm-body' flexGrow={1} h='full'>
        {children}
      </Body>
      <Footer id='ftm-footer' />
    </Flex>
  )
}

export default Layout
