import React from 'react'
import {
  Box,
  BoxProps,
  Center,
  Link,
  Text,
  useTheme,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

export interface FooterProps extends BoxProps {
  //
}

function Footer(props: FooterProps) {
  const { colorMode } = useColorMode()
  const theme = useTheme()
  const bgColor = useColorModeValue(
    theme.colors.background.light.accent,
    theme.colors.background.dark.accent,
  )
  return (
    <Box as='footer' bg={bgColor} h={150} {...props}>
      <Center>{null}</Center>
    </Box>
  )
}

export default Footer
