import React from 'react'
import { Box, BoxProps, useTheme, useColorModeValue } from '@chakra-ui/react'
import type { PageProps } from 'gatsby'

function Body({
  children: childrenProp,
  ...rest
}: BoxProps & Partial<Omit<PageProps, 'children'>>) {
  const theme = useTheme()

  const backgroundColor = useColorModeValue(
    theme.colors.background.light.primary,
    theme.colors.background.dark.primary,
  )

  let children: React.ReactNode

  if (React.isValidElement(childrenProp)) {
    children = React.cloneElement(childrenProp, {
      ...childrenProp.props,
      style: {
        ...childrenProp.props?.style,
      },
    })
  } else {
    children = childrenProp
  }

  console.log(backgroundColor)

  return (
    <Box as='main' backgroundColor={backgroundColor} {...rest}>
      {children}
    </Box>
  )
}

export default Body
