import React from 'react'
import {
  Box,
  BoxProps,
  Center,
  Image,
  ImageProps,
  Text,
  Wrap,
  useColorMode,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react'
import Link from 'components/Link'
import logo from 'resources/images/logo.png'

export interface NavbarProps extends BoxProps {
  //
}

const Logo: React.FC<ImageProps> = ({ src, ...rest }) => (
  <Link to='/'>
    <Image src={src} {...rest} width={150} />
  </Link>
)

const Links: React.FC = () => {
  const textColor = useColorModeValue('#333', '#eee')
  return (
    <Wrap display='flex' alignItems='center' pr={20}>
      <Link to='/' style={{ color: textColor }}>
        Home
      </Link>
      <span style={{ width: 25 }} />
      <Link to='/about' style={{ color: textColor }}>
        About
      </Link>
      <span style={{ width: 25 }} />
      <Link to='/contact' style={{ color: textColor }}>
        Contact
      </Link>
    </Wrap>
  )
}

function Navbar({ sx: sxProp, ...rest }: NavbarProps) {
  const { colorMode } = useColorMode()
  const theme = useTheme()
  console.log({ theme })

  return (
    <Box
      as='nav'
      {...rest}
      sx={{
        bg: theme.colors.background[colorMode].primary,
        ...sxProp,
      }}
    >
      <Box display='flex' justifyContent='space-between'>
        <Logo src={logo} />
        <Links />
      </Box>
    </Box>
  )
}

export default Navbar
