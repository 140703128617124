import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'

export interface LinkProps extends GatsbyLinkProps<any> {
  //
}

function Link(props: LinkProps) {
  return <ChakraLink as={GatsbyLink} {...props} />
}

export default Link
