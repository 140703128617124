import { extendTheme, theme as chakraTheme } from '@chakra-ui/react'
import type { Theme, ThemeTypings } from '@chakra-ui/react'

// @ts-expect-error
const theme = {
  ...chakraTheme,
  config: {
    initialColorMode: 'dark',
  },
  colors: {
    background: {
      light: {
        primary: '#eee',
        accent: '#0D141C',
      },
      dark: {
        primary: '#0D141C',
        accent: '#755234',
      },
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Roboto',
        background: '#0D141C',
      },
    },
  },
} as Theme

export default extendTheme(theme)
