import React from 'react'
import {
  ChakraProvider,
  ColorModeProvider,
  createLocalStorageManager,
} from '@chakra-ui/react'
import theme from './src/config/theme'
import Layout from './src/components/Layout'

export function wrapPageElement({ element, props: pageProps }) {
  return <Layout {...pageProps}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return (
    <ChakraProvider theme={theme} portalZIndex={40}>
      <ColorModeProvider
        colorModeManager={createLocalStorageManager('ftm-color-mode')}
        options={{ initialColorMode: 'dark' }}
      >
        {element}
      </ColorModeProvider>
    </ChakraProvider>
  )
}
